import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { fileUploadApi } from "../apis";

export const fileUploadAction = (
  path: string,
  body: FormData,
  type: string,
  onUploadProgress: (data: any) => void,
  onSuccessCallback: any,
  onErrorCallback: (error: any) => void
): ThunkAction<void, any, null, Action<string>> => {
  return async (dispatch: any, getState: any) => {
    await fileUploadApi(
      path,
      body,
      type,
      onUploadProgress,
      onSuccessCallback,
      onErrorCallback
    );
  };
};
