export const LOGO = require("./smap-logo.png");
export const AVAILABLE_ON_APP_STORE = require("./aivalable-on-the-app-store.png");
export const AVAILABLE_ON_PLAY_STORE = require("./available-on-play-store.png");
export const UAE = require("./united-arab-emirates.png");
export const UK = require("./united-kingdom.png");
export const US = require("./united-states.png");
export const FILTERS = require("./filters.png");
export const CATEGORIES = require("./categories.png");
export const LANGUAGE = require("./language.png");
export const SPECIALITIES = require("./specialities.png");
export const CERTIFICATE = require("./certificate.png");
export const VERIFIED = require("./verified.png");
export const CHAT = require("./chat.png");
export const FACEBOOK = require("./facebook.png");
export const INSTAGRAM = require("./instagram.png");
export const TWITTER = require("./twitter.png");
export const YOUTUBE = require("./twitter.png");
export const GOOGLEMAP = require("./googlemap.png");
export const SIGN_IN_WITH_FACEBOOK = require("./sign-in-facebook.png");
export const SIGN_IN_WITH_GOOGLE = require("./sign-in-google.png");
export const SIGN_IN_WITH_APPLE = require("./sign-in-apple.png");
export const RUNNER_IMAGE_BLACK = require("./runnerBlack.png");
export const LOCATION_PIN = require("./locationPin.png");
export const IC_TICK = require("./icTick.png");
export const IC_SELECT = require("./icSelect.png");
export const IC_BOX = require("./icBox.png");
export const MIDDLE_BOX = require("./middleBox.png");
export const TOP_BOX = require("./topBox.png");
export const IC_SEND = require("./icSend.png");
export const BOTTOM_BOX = require("./bottomBox.png");
export const Back_ARROW = require("./back-arrow.png");
export const BACKGROUND_IMAGE = require("./backgroundImage.png");
export const DISABLED = require("./disabled.png");
