import { axiosInstance } from "../axios";

const postCreateRequestOpportunityApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post(
      "/request-opportunities/create",
      body
    );
    successCallback(response);
    return response;
  } catch (error) {
    errorCallback(error);
    return error;
  }
};

const postCreateQuickRequestOpportunityApi = async (
  body: any,
  successCallback: (response: any) => void,
  errorCallback: (error: any) => void
) => {
  try {
    let response = await axiosInstance.post("/createUserAndBroadcast", body);
    successCallback(response);
    return response;
  } catch (error) {
    errorCallback(error);
    return error;
  }
};

const getOpenFormsApi = async (category_id: number) => {
  try {
    let response = await axiosInstance.get(
      `/open/forms/get-all/${category_id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  postCreateRequestOpportunityApi,
  postCreateQuickRequestOpportunityApi,
  getOpenFormsApi,
};
