import { axiosInstance } from "../axios";

export async function fileUploadApi(
  path: string,
  body: FormData,
  type: string,
  onUploadprogress: any,
  onSuccessCallback: any,
  onErrorCallback: (error: any) => void
) {
  try {
    let response = await axiosInstance.post(`/upload/to/${path}`, body, {
      onUploadProgress: (progressEvent) => {
        console.log(progressEvent);
        const { loaded, total } = progressEvent;
        let percentage = Math.floor((loaded * 100) / total);
        onUploadprogress(percentage);
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    onSuccessCallback(response.data, type);
  } catch (error) {
    onErrorCallback(error);
  }
}
